@import '../../../assets/sass/custom_variables/variables';

.FullLoader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($white, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__icon {
    width: 90px;
    height: 90px;
    background: $white;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70px;
    }
  }
  &__text {
    color: $gray-900;
    margin-top: rem(10);
  }
}

